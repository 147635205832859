import { AxiosError } from "axios";
import { httpPeriodicReport, httpFirebase } from "@/services/axios.service";

export interface PeriodicResponseServiceResponse {
  success: boolean;
  data: any;
}

export interface PeriodicRegenerateResponseServiceResponse {
  success: boolean;
  data: any;
}

export interface GetSearchParams {
  account: string;
  periodSelected: string;
  dateSelected: string;
}

export interface PostSendParams {
  account: string;
  html: string;
  email: string;
}

export interface PostRegenerateParams {
  account: string;
  date: string;
}

export const GETSearch = async (
  GetSearch: GetSearchParams
): Promise<PeriodicResponseServiceResponse> => {
  try {
    const resData =
      await httpPeriodicReport.get<PeriodicResponseServiceResponse>(
        `${GetSearch.account}/report/html?filters.type=${GetSearch.dateSelected}&filters.timestamp=${GetSearch.periodSelected}`
      );
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};

export const POSTSendEmail = async (
  PostSendReport: PostSendParams
): Promise<void> => {
  try {
    const resData = await httpFirebase.post<void>("sendReport", {
      account: PostSendReport.account,
      html: PostSendReport.html,
      email: PostSendReport.email,
    });
    return resData.data;
  } catch {
    return Promise.reject("Error");
  }
};

export const POSTRegenerate = async (
  PostSendReport: PostRegenerateParams
): Promise<void> => {
  try {
    const url = `${PostSendReport.account}/daily?filters.timestamp=${PostSendReport.date}`;
    const resData = await httpPeriodicReport.post<void>(url, {});
    return resData.data;
  } catch {
    return Promise.reject("Error");
  }
};
