import {
  VariantKeys as VariantKeysInput,
  VariantsType as InputVariantsType,
} from "@/components/atoms/Input";
import { VariantKeys as VariantKeysButton } from "@/components/atoms/Button";

//Variants
interface VariantsCombination {
  primary: {
    input: InputVariantsType;
    button: VariantKeysButton;
  };
  secondary: {
    input: InputVariantsType;
    button: VariantKeysButton;
  };
  none: {
    input: InputVariantsType;
    button: VariantKeysButton;
  };
}

export const variantCombination: VariantsCombination = {
  primary: {
    input: VariantKeysInput.secondary,
    button: VariantKeysButton.primary,
  },
  secondary: {
    input: VariantKeysInput.primary,
    button: VariantKeysButton.secondary,
  },
  none: {
    input: VariantKeysInput.none,
    button: VariantKeysButton.none,
  },
};

export enum VariantKeys {
  primary = "primary",
  secondary = "secondary",
  none = "none",
}

export type VariantsType = keyof typeof variantCombination;
