import {
  GETSearch,
  GetSearchParams,
  POSTSendEmail,
  PostSendParams,
  POSTRegenerate,
} from "@/services/api/periodicReports.api";
// Notification
import { ShowNotification } from "@/helpers/notification.helper";
import { format, startOfDay, isBefore } from "date-fns";

export interface PeriodicReportServiveGet {
  success: boolean;
  data: any;
}

export interface PeriodicRegenerateReportServivePost {
  success: boolean;
  data: any;
}

interface ParamsPeriodicReport {
  account: string;
  periodSelected: string;
  dateSelected: string;
}

interface ParamsSendReport {
  account: string;
  html: string;
  email: string;
}

interface ParamsRegenerateReport {
  account: string;
  date: string;
}

export const Search = async (
  credentials: ParamsPeriodicReport
): Promise<PeriodicReportServiveGet> => {
  try {
    // Mapeo
    const RefreshParams: GetSearchParams = {
      account: credentials.account,
      periodSelected: credentials.periodSelected,
      dateSelected: credentials.dateSelected,
    };
    const response = await GETSearch(RefreshParams);
    return { ...response };
  } catch {
    ShowNotification(
      "Error",
      "Hubo un error al buscar el reporte. Intenta más tarde.",
      "error"
    );
    return Promise.reject({});
  }
};

export const Send = async (credentials: ParamsSendReport): Promise<void> => {
  try {
    const SendParams: PostSendParams = {
      account: credentials.account,
      html: credentials.html,
      email: credentials.email,
    };
    await POSTSendEmail(SendParams);
    ShowNotification("Mail enviado", "El reporte se envío con éxito", "valid");
  } catch {
    ShowNotification(
      "Error",
      "Hubo un error al intentar enviar el reporte. Intenta más tarde.",
      "error"
    );
    return Promise.reject({});
  }
};

export const Regenerate = async (
  credentials: ParamsRegenerateReport
): Promise<void> => {
  try {
    const formatDate = format(
      startOfDay(new Date(credentials.date)),
      "yyyy-MM-dd'T'HH:mm:ss'Z'"
    );
    const RegenerateParams: ParamsRegenerateReport = {
      account: credentials.account,
      date: formatDate,
    };
    await POSTRegenerate(RegenerateParams);
    return;
  } catch (e) {
    console.log(e);
    return Promise.reject({});
  }
};
